<template>
    <div class="mbundle-home">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="richtext-area">
                    <span v-html="mbundle.promises"></span>
                </div>
                <div class="richtext-area">
                    <span v-html="mbundle.targetAudience"></span>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="authors">
                    <div class="author" v-for="(author, index) in primaryAuthors" :key="`author-${index}`">
                        <img :src="author.avatar" :alt="author.name" loading="lazy" />
                        <div class="details">
                            <h4 v-if="author.name">
                                {{ author.name }}
                            </h4>
                            <h5 v-if="author.role">
                                {{ author.role }}
                            </h5>
                            <span v-if="author.description" v-html="author.description"></span>
                        </div>
                    </div>
                </div>
                <div v-if="mbundle.providers && mbundle.providers.length" class="providers">
                    <h5>
                        {{ labels.providers }}
                    </h5>
                    <div v-for="(provider, index) in mbundle.providers" :key="`provier-${index}`" class="provider">
                        <img :src="provider.image" />
                        <span v-html="provider.description"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpaceSingleMbundleHome',
    props: {
        mbundle: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            labels: {
                providers: 'Providers'
            }
        }
    },
    computed: {
        primaryAuthors() {
            let result = [];

            if (this.mbundle?.authors) {
                for (let i = 0; i < this.mbundle.authors.length; i++) {
                    if (this.mbundle.authors[i].primaryAuthor) result.push(this.mbundle.authors[i]);
                }
            }

            return result;
        }
    },
    methods: {
        
    },
    components: {
        
    }
}
</script>

<style scoped lang="scss">
    @import '../../../styles/spaces_common.scss';

    .mbundle-home {
        .richtext-area,
        .author,
        .providers {
            background-color: #fff;
            box-shadow: $box_shadow;
            margin-bottom: 1rem;
            padding: 1rem;
        }

        .authors {
            margin-bottom: 2rem;

            .author {
                display: flex;
                margin-bottom: 2rem;

                &:last-child {
                    margin-bottom: 0px;
                }

                img {
                    max-width: 220px;
                    max-height: 200px;
                    object-fit: contain;
                    object-position: left top;
                }

                h4 {
                    font-size: 1.3rem;
                    margin-bottom: 0.3rem;
                }

                h5 {
                    font-size: 1rem;
                    margin-bottom: 0.5rem;
                }

                span {
                    display: block;
                    flex: 1 0 auto;
                }

                .details {
                    margin-left: 1rem;
                    max-height: 300px;
                    overflow: hidden;
                }
            }
        }

        .provider {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 2rem;
            padding-bottom: 2rem;
            border-bottom: 1px solid rgba(0,0,0,0.3);

            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
                margin-bottom: 0;
            }

            img {
                max-width: 80%;
                height: 120px;
                object-fit: contain;
                object-position: center;
                margin-bottom: 1rem;

                @media (max-width: 767.98px) {
                    max-width: 100%;
                }
            }

            span {
                display: block;
                width: 100%;
            }
        }
    }
</style>